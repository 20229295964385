import { DynamicImage } from '@components/common'
import DynanmicVideoInView from '@components/common/DynamicVideo/DynamicVideoInView'
import { Button } from '@components/ui'
import Link from '@components/ui/Link'
import { toggleChat } from '@lib/gorgias'
import { SplitPanelProps } from '@lib/types/SplitPanel'
import cn from 'classnames'
import { RichText } from 'prismic-reactjs'
import { FC } from 'react'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
const fullConfig = resolveConfig(tailwindConfig as any)

interface props {
  data: SplitPanelProps
  applyWrapper?: boolean
}

const SplitPanel: FC<props> = ({ data, applyWrapper = true }) => {
  const mainImage = data.main_image_desktop
  const mainImageMobile = data.main_image_mobile?.url ? data.main_image_mobile : data.main_image_desktop
  const contentImage = data.content_image_desktop
  const contentImageMobile = data?.content_image_mobile?.url ? data.content_image_mobile : data.content_image_desktop
  const { main_image_alignment, padding_top, enable_live_chat_button, video_main, video_content } = data
  const { screens } = fullConfig.theme as Theme
  const breakPointNum = parseInt(screens['tablet'].split('px').join(''))

  const handleOpenLiveChat = () => {
    toggleChat()
  }
  return (
    <section className="split-panel" style={{ backgroundColor: data.background_color }}>
      <div
        className={`split-panel__wrapper wrapper  grid grid-cols-8 gap-x-12 tablet:grid-cols-12 tablet:gap-x-16 pb-40 tablet:pb-80${
          padding_top ? ' pt-40 tablet:pt-80' : ''
        }`}
        style={{ backgroundColor: data.background_color }}
      >
        <div className={cn(`split-panel__image relative col-span-8 tablet:col-span-7 -mx-20 tablet:mx-0`)}>
          {mainImage?.url && <DynamicImage mediaDesktop={mainImage} mediaMobile={mainImageMobile} />}
          {video_main?.url && (
            <DynanmicVideoInView
              mediaDesktop={video_main}
              mediaMobile={video_main}
              breakPoint={breakPointNum}
              settings={{ playsInline: true, loop: true, muted: true }}
              style="absolute top-0"
            />
          )}
        </div>

        <div className="split-panel__content col-span-8 tablet:col-span-4 tablet:col-start-9">
          {(contentImage?.url || video_content?.url) && (
            <div className={cn(`split-panel__image relative mt-32 tablet:mt-0`)}>
              {contentImage?.url && <DynamicImage mediaDesktop={contentImage} mediaMobile={contentImageMobile} />}
              {video_content?.url && (
                <DynanmicVideoInView
                  mediaDesktop={video_content}
                  mediaMobile={video_content}
                  breakPoint={breakPointNum}
                  settings={{ playsInline: true, loop: true, muted: true }}
                  style="absolute top-0"
                />
              )}
            </div>
          )}
          <div className="mt-32 tablet:mt-48 tablet:max-w-394">
            {data.content_title && <div className="title">{data.content_title}</div>}
            {data.content_description && <div className="mt-12 body">{RichText.render(data.content_description)}</div>}
            {data.phone_number && (
              <div className="body">
                <br />
                Chat or call us&nbsp;
                <Link href={`tel:${data.phone_number}`}>
                  <span className="underline">{data.phone_number}</span>
                </Link>
              </div>
            )}
          </div>
          {data.button_text && !enable_live_chat_button && (
            <div className="mt-32 tablet:mt-48">
              {data.button_link && (
                <Link href={data.button_link}>
                  <Button variant="primary">{data.button_text}</Button>
                </Link>
              )}
              {!data.button_link && <Button variant="primary">{data.button_text}</Button>}
            </div>
          )}
          {data.button_text && enable_live_chat_button && (
            <div className="mt-32 tablet:mt-48" onClick={handleOpenLiveChat}>
              <Button variant="primary">{data.button_text}</Button>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .split-panel__wrapper {
          direction: ${main_image_alignment === 'Left' ? 'ltr' : 'rtl'};
        }

        .split-panel__content {
          direction: ltr;
        }

        .split-panel__image {
          padding-top: 100%;
        }
      `}</style>
    </section>
  )
}

export default SplitPanel
